import React from "react"

// Comps
import Container from "../components/containers/Container"

// Parts
import Panel404 from "../components/panels/Panel404"

// MISC
import Metas from "../components/metas/Metas"

const QuatreCentQuatrePage = props => {
    return (
        <Container location={props.location}>
            <Metas
                title="Quatre Cent Quatre"
                description="J'écris des trucs sur mon confinement"
                image=""
                url={props.location.href}
            />
            <Panel404 />
        </Container>
    )
}

export default QuatreCentQuatrePage
