import React from "react"

// Comps
import { Row, Cell } from "../layout/Layout"

import { Link } from "gatsby"

const Panel404 = props => {
    return (
        <Row>
            <Cell start="0" end="12">
                <h2 className="teasing-1 trailer-16">
                    Oh, it's a four-oh-four we have there.
                </h2>
                <p className="trailer-32">Nothing much to see here, sorry !</p>
                <Link to="/" className="link">
                    Back to the homepage
                </Link>
            </Cell>
        </Row>
    )
}

export default Panel404
